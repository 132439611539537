.container {
    padding-bottom: 50px;
    padding: 0% 7% 1% 12%;
    margin: auto;
    margin-bottom: 50px;
}

.innerContainer {
    width: 75%;
    /* padding: 3%; */
}



.cardButtonPrimary {
    width: 90%;
    border-radius: 25px;
    box-shadow: 2px 2px 6px #bebebe99,
        -2px -2px 6px #ffffff99;
}

.cardButtonSecondary {
    width: 90%;
    border-radius: 25px;
    box-shadow: 2px 2px 6px #bebebe33,
        -2px -2px 6px #ffffff33;
}

.backTitle {
    font-size: 14px;
    font-weight: 400;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 2fr));
    grid-gap: 1rem;
    margin-bottom: 70px;
}

.itemLabel {
    margin-bottom: 5px;
    font-weight: 500;
}

@media only screen and (max-width: 900px) {
    .innerContainer {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 0.5rem;
    }

    .innerContainer {
        width: 100%;
    }


}

@media only screen and (max-width: 400px) {
    .container {
        padding: 0.5rem;
    }
}

.bankGrid {
    display: grid;
    gap: 50px;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0px;
    margin-bottom: 25px;
    text-align: right;
    /* justify-content: flex-end; */
    /* grid-template-columns: 2fr 2fr 1fr; */
    grid-template-columns: repeat(auto-fit, 150px);

}

.bankGridRight {
    display: grid;
    gap: 15px;
    /* flex-wrap: wrap; */
    width: 100%;
    margin: 15px 0px;
    margin-bottom: 25px;
    text-align: right;
    justify-content: flex-end;
    /* grid-template-columns: 2fr 2fr 1fr; */
    grid-template-columns: repeat(auto-fit, 225px);
}


.formCard {
    width: 48%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #3AB44D20;
    padding: 35px;
    padding-bottom: 10px;
    /* // margin: 2% 0%; */
    border-radius: 25px;
    /* background-color: #3AB44D20; */
    min-width: 450px;
    box-shadow: 20px 20px 60px #bebebe33, -20px -20px 60px #ffffff33;
    transition: ease 0.5s;
}

.actionContainer {
    position: absolute;
    top: 20px;
    right: 22px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 10px;
    min-width: 250px;
    width: 50%;
}

@media (max-width: 1024px) {
    .actionContainer {
        top: 125px;
    }
}

.infoCard {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 425px;
    padding: 25px;
    /* box-shadow: 20px 20px 60px #bebebe55, -20px -20px 60px #ffffff55; */
    /* border-radius: 25px; */
    /* border: 1px solid #3a3a3a33; */
    /* justify-content: center; */
    /* align-content: center; */
    /* align-items: center; */
}

.sectionContainer {
    width: 42%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 25px;
    min-width: 425px;
    /* padding: 25px 0px; */
    gap: 5px;
}

.sectionContainerRight {
    width: 52%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 25px;
    min-width: 425px;
    /* padding: 25px 0px; */
    gap: 5px;
}

@media (max-width: 1435px) {
    .sectionContainer {
        width: 100%;
    }

    .sectionContainerRight {
        width: 100%;
    }
}

.detailsLogoContainer {
    width: 125px;
    height: 125px;
    border-radius: 100%;
    overflow: hidden;
    line-height: 125px;
    text-align: center;
    position: absolute;
    top: -55px;
    left: 55px;
    border: 1px solid #3AB44D33;
    box-shadow: 20px 20px 60px #bebebe99, -20px -20px 60px #ffffff99;
    background-color: white;
}

.pageContainer {
    background-color: #F8F8FF;
    border: 1px dashed #A9A9A9;
    width: 100%;
    border-radius: 25px;
    margin-top: 75px;
    position: relative;
    padding: 75px 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 650px;
}

.pageTitle {
    display: flex;
    position: absolute;
    top: 75px;
    left: 50px;
    align-items: center;
    gap: 15px;

}

.deleteMenu {
    border-radius: 10px;
    /* background-color: red; */
    padding: 0px;

}

.deleteMenu:hover {
    /* background-color: yellow; */
    padding: 0px;
}