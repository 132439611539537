.pageContainer {
  display: flex;
  /* height: 100vh; */
}

.contentContainer {
  padding: 0px 50px;
  right: 0;
  width: 100%;
  margin: 10px auto;
  overflow-y: scroll;

}

.sideBar {
  width: 7%;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3ab44d;
  transition: all 300ms linear;
  gap: 15%;
}

.sideBarOpen {
  width: 12.5%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3ab44d;
  transition: all 300ms linear;
  gap: 12%;
  padding: 25px;
}

.logo {
  height: 40px;
  margin-top: 40px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: flex-start;
  gap: 25px;
  margin-top: -50px;
}

.linksOpen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 50%;
  justify-content: flex-start;
  gap: 25px;
  margin-top: -50px;
}

.linkItem {
  font-weight: 300;
  color: #fff;
}

.linkItem p {
  margin-left: 12px;
}

.linkItem:hover {
  color: #fff;
}

.sidebarIcon {
  height: 22px;
}

.sidebarIconActive {
  border-radius: 5px;
  padding: 10px;
  box-shadow: inset 0px 0px 20px 10px rgba(255, 255, 255, .3);
  height: 40px;
}

.sidebarIconActiveOpen {
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 1000px rgba(255, 255, 255, .3);
  margin-left: -10px;
}

.linkItemActive {
  font-weight: 600;
  color: white;
}

.linkItemActive:hover {
  color: white;
}

.linkItemActive .sidebarIcon {
  display: none;
}

.linkItem .sidebarIconActive {
  display: none;
}

.linkItem .sidebarIconActiveOpen {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .container {
    padding: 0% 5% 1% 5%;
    display: flex;
  }

  .formContainer {
    width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    padding: 0%;
  }
}

.openSidebarContainer {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 5px;
  width: 100%;
}

.openSidebarIconContainer {
  width: 25px;
  margin: 0px 10px;
}

.closedSidebarIconContainer {
  display: flex;
  justify-content: center;

}

.sidebarText {
  word-break: keep-all;
  white-space: initial;
  margin-bottom: 0px;
}

.menu {
  border-radius: 12px;
  min-width: 145px;
  margin-top: -10px;
}

.statusContainer {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  line-height: 5px;
  gap: 7px;
  margin-top: 10px;
}

.statusDot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #3AB44D;
}

.addUserFormContainer {
  padding: 25px;
  padding-top: 75px;
  min-width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}

.formFieldHalf {
  width: 48%;
  margin-bottom: -5px;
}

.formInputContainer {
  display: flex;
  gap: 5px;
  margin-bottom: -5px;
}

.contain {
  width: 100%;
  margin: auto;
  transition: all 1s ease-in-out;
  height: 100vh;
  overflow-y: scroll;
}

.mainContainer {
  display: flex;
  max-height: 100vh;
  overflow: 'hidden';
}