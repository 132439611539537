body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8 !important;

}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
}

.ant-custom-input {
  border-radius: 25px !important;
}

.ant-btn-group {
  --antd-wave-shadow-color: #f87d4e !important;

}

.ant-btn-group.ant-dropdown-button>.ant-btn {
  --antd-wave-shadow-color: #f87d4e55 !important;
  border-color: #f87d4e55;
  color: #f87d4e;
}

.ant-btn-group.ant-dropdown-button>.ant-btn:hover {
  --antd-wave-shadow-color: #f87d4e !important;
  border-color: #f87d4e;
  color: #f87d4e;
}

.ant-btn-group.ant-dropdown-button>.ant-btn:first-child:not(:last-child) {
  border-radius: 10px 0px 0px 10px !important;
}

.ant-btn-group.ant-dropdown-button>.ant-btn:last-child:not(:first-child) {
  border-radius: 0px 10px 10px 0px !important;
}

.ProviderDetail_deleteMenu__Ujz0R>.ant-dropdown-menu-item {
  padding: 10px;
}

.ProviderDetail_deleteMenu__Ujz0R>.ant-dropdown-menu-item-active {
  background-color: #ff0000 !important;
  color: white !important;
  border-radius: 10px !important;
}

.ProviderDetail_deleteMenu__Ujz0R>.ant-dropdown-menu-item-disabled {
  border-radius: 10px !important;
}

.ant-select-selector {
  border: none !important;
}

.ant-select-selection-search-input {
  padding-left: 8px !important;
}

.ant-select-selection-placeholder {
  padding-left: 8px !important;
}

/* ant-radio-group-outline ant-radio-group-small ant-picker-calendar-mode-switch */
.ant-picker-calendar-mode-switch {
  border-radius: 25px !important;
  overflow: hidden;
  border: solid 0.75px gray
}

.ant-radio-button-wrapper {
  border: none !important;
}

.ant-picker-cell-inner {
  border-radius: 25px !important;
}

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 8px !important;
}

.ant-input {
  border-radius: 12px !important;
}


input[type='file'] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.uploadedFile:hover {
  background-color: rgb(242, 240, 240);
}

.box {
  background-color: white;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 2px 1px rgba(229, 226, 226, 0.2);
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(235, 233, 233, 0.22); */
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 25px !important;
}

.strongLabel {
  font-weight: 700;
}

.strongLabell {
  font-weight: 500;
}

.fieldLabel {
  font-size: 14px;
  font-weight: bold;
}

.wFull {
  width: 100%;
}

.wHalf {
  width: 50%;
}

.mtLarge {
  margin-top: 30px;
}

.mbLarge {
  margin-bottom: 30px;
}

.mlLarge {
  margin-left: 30px;
}

.mrLarge {
  margin-right: 30px;
}

.mbZero {
  margin-bottom: 0px !important;
}

.mbFive {
  margin-bottom: 5px !important;
}

.customAntInput {
  border-radius: 22px !important;
  height: 50px;
}

.custom-ant-button {
  height: 50px !important;
}

.importantIcon {
  object-fit: contain;
  width: 10px;
  height: 17px;
  margin-right: 3px;
}

.dfaCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dfsBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dGrid {
  display: grid;
  place-items: center;
}

.addBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addBoxWhite {
  display: grid;
  place-items: center;
  width: 170px;
  height: 40px;
  color: black;
  background-color: #fff;
}

.activeTopNavLi {
  border-bottom: #f87d4e 3px solid;
}

.ant-modal-content {
  /* width: 470px; */
  width: 100%;
  border-radius: 23px !important;
}

.commonModal .ant-modal-content {
  width: 370px !important;
}

.fileClaim .ant-modal-content {
  width: 520px !important;
}

.ant-upload.ant-upload-drag {
  border-radius: 20px !important;
}

.modalCloseIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
}

.ant-modal-close {
  display: none !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  border-radius: 50%;
  background-color: #f87d4e;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-radio-wrapper-checked span {
  color: #f87d4e !important;
  font-weight: 500;
  border-radius: 25px !important;
}

.ant-divider-horizontal {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.ant-picker-input,
.ant-picker.ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}

.ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 10px;
  color: #f87d4e;
}

.ant-select .ant-select-selector {
  border-radius: 12px !important;
}

.ant-select-dropdown {
  border-radius: 13px !important;
}

.ant-select-arrow {
  color: #f87d4e !important;
}

.ant-collapse-header-text {
  width: 100%;
  padding-bottom: 10px;
  font-weight: 700;
}

.ant-collapse-item-active .ant-collapse-header-text {
  /* border-bottom: 1px solid #3AB44D99; */
}

.support .ant-select-selection-placeholder {
  font-size: 14px !important;
}

.support .ant-select-selector {
  border: none !important;
}

.ant-upload-list-item-name {
  max-width: 250px !important;
}

.ant-form-item-control {
  width: 100% !important;
}

.ant-upload-list-item-info {
  border-radius: 10px !important;
  padding-left: 10px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  --webkit-appearance: none;
  margin: 0;
}

/* Ant table css customization starts from here*/

.ant-table-thead>tr>th {
  background-color: white !important;
  border-bottom: 4px solid #fce4d9 !important;
}

.ant-table-thead .ant-table-cell {
  color: #f87d4e !important;
  font-weight: bold;
  padding-bottom: 6px !important;
}

.ant-table-cell {
  font-size: 13px !important;
}

.dashBoardTable .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.inpatientDetail .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #fce4d9 !important;
  color: #f87d4e !important;
  font-weight: bold;
  padding-bottom: 10px !important;
}

.ant-table-thead .ant-table-cell {
  font-size: 13px !important;
  cursor: auto;
}

.ant-table-cell {
  font-size: 12px !important;
}

.ant-table-cell {
  cursor: pointer;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
  flex: inherit;
}

/* Ant table css customization over here*/

.ant-picker-panel-container {
  border-radius: 14px !important;
}

.ant-picker-ok>button {
  border-radius: 7px !important;

}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}